import React from "react"
import { ErrorRepeat } from "../../../components/ErrorsVariants"

import LayoutOrder from "../../../components/Layout/LayoutOrder"

export default function ErrorRepeatData() {
  return (
    <div className="error-pages">
      <LayoutOrder>
        <div className="error-pages-wrapper">
          <ErrorRepeat />
        </div>
      </LayoutOrder>
    </div>
  )
}
